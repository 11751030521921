//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom variables
// $base-font-size: 15px;
//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom variables
$base-font-size: 0.875rem;


//$menu-dark-bg-color: #22272e !default;
//$theme-dark-body-bg: #22272e !default;
//$theme-dark-body-color: #d0d2d6 !default;
//$theme-dark-border-color: #3b4253 !default;
//$theme-dark-custom-control-border-color: #44405e !default;
//
////Typography
//$theme-dark-headings-color: #d0d2d6 !default;
//$theme-dark-label-color: #d0d2d6 !default;
//$theme-dark-text-muted-color: #676d7d !default;
//
////Card
//$theme-dark-card-bg: #22272e !default;
//$theme-dark-box-shadow: #22272e;
//
////Input
//$theme-dark-input-bg: #22272e !default;
//$theme-dark-input-placeholder-color: #676d7d !default;
//$theme-dark-input-border-color: #404656 !default;
//$theme-dark-input-disabled-bg: #22272e !default;
//$theme-dark-input-disabled-border-color: #444b60 !default;
//
//// Switch
//$theme-dark-switch-bg: #545a6a;
//$theme-dark-switch-bg-disabled: #22272e;
//
////Table
//$theme-dark-table-bg: #22272e !default;
//$theme-dark-table-header-bg: #343d55 !default;
//$theme-dark-table-row-bg: #283046 !default;
//$theme-dark-table-hover-bg: #242b3d !default;
//$theme-dark-table-striped-bg: #242b3d !default;
//
//$theme-dark-modal-header-bg: #22272e !default;
//$theme-dark-pagination-bg: #22272e !default;
//$theme-dark-chart-bg: #384056 !default;
//$theme-dark-widget-bg: #384056 !default;
